body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/montserrat-regular.woff"); /* IE9 Compat Modes */
  src: local("Montserrat"), format("woff"),
    /* Modern Browsers */ url("./fonts/montserrat-regular.woff")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/montserrat-regular.woff")
      format("svg"); /* Legacy iOS */
}

body {
  font-family: "Montserrat";
  /* background-color: #; */
  background: linear-gradient(180deg, #010a13 0%, #041722 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  -webkit-font-smoothing: subpixel-antialiased;
}

html {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
